$thin-line : 3px;

.ill {
    // outline: 2px solid blue;
    position: absolute;
    top:0;
    right:0;
    width: 40vw;
    height: 100vh;
    
    @media all and (max-width: 576px) {
        top: 20px;
        right: -10%;
    }

    &__elm {
        position: absolute;

        // downsize on mobile
        @media screen and (max-width: 576px) {
            transform: scale(.5);
            transform-origin: 0% 100%;
        }

    }

    /**
     * blue line
     **/
    .blue-line {

        top: 200px;
        left: 20vw;
        @media all and (min-width: 576px) {
            top: 400px;
        }

        &.circle {
            width: 45px;
            height: 45px;
            background-color: #50BDF1;
        }

        & > .line {
            position: absolute;
            width: 1500px;
            height: $thin-line;
            top: 21px;
            left: 21px;
            background-color: #50BDF1;
            transform-origin: 0 100%;
        }

        & > .line--left {
            transform: rotate(-110deg);
            @media all and (min-width: 576px) {
                transform: rotate(-110deg);
            }
        }

        & > .line--right {
            transform: rotate(5deg);
        }
    }

   /**
     * orange line
     **/
     .orange-line {

        opacity: 0;
        top: 250px;
        left: 90%;
        @media all and (min-width: 576px) {
            opacity: 1;
            left: 90%;
            top: 550px;
        }

        &.circle {
            width: 20px;
            height: 20px;
            background-color: #E86F33;
        }

        & > .line {
            position: absolute;
            width: 1500px;
            height: $thin-line;
            top: 10px;
            left: 10px;
            background-color: #E86F33;
            transform-origin: 0 100%;
        }

        & > .line--left {
            transform: rotate(-110deg);
        }

        & > .line--right {
            transform: rotate(-45deg);
        }
    }


    /**
     * green line
     **/
     .green-line {
        top: 0;
        right:0;

        width: 20vw;
        height: 40vh;

        @media all and (min-width: 576px) {
            width: 25vw;
            height: 80vh;
        }

        & > .dot {
            position: absolute;
            top: 100%;
            width: 40px;
            height: 40px;
            background-color: #339A6F;
            border-radius: 50%;
            transform: translate(-50%, -50%);
        }

        & > .circle--outer {

            top: 100%;
            left: 0%;
            width: 90px;
            height: 90px;

            border-style: solid;
            border-color: #339A6F;
            border-width: $thin-line;
            background:transparent;
            transform-origin: center;
        }
        & > .line {
            position: absolute;
            width: 1500px;
            height: $thin-line;
            top: 100%;
            left: 0;
            background-color: #339A6F;
            transform-origin: 0 100%;
            transform: rotate(-65deg);
        }


    }


    /**
      * yellow line
    **/
    $radius : 746 * 1.7;

    .yellow-line {
        top: ($radius * -1) + 0 + px;
        left: -100%;
        // top: ($radius * -1) + 0 + px;
        // outline: 2px solid red;
        width: $radius * 2 + px;
        height: $radius + px;
        border-top-left-radius: $radius + px;
        border-top-right-radius: $radius + px;

        border: 6px solid #FDD43C;

        @media all and (min-width: 576px) {
            left: 50%;
            border-width: 7px;
        }

        border-bottom: 0;
        // transform: translateX(-100%);
        transform-origin: 50% 100%;
    }

    /**
    * red line
    **/
    $red-radius : 1000;
    .red-line {

        // outline: 1px solid blue;
        // left: -50px;
        top: $red-radius / .56 * -1 + px;
        left: $red-radius / 3 * -1 + px;


        // @media all and (min-width: 576px) {
        //     left: -300px;
        // }

        // top: $red-radius * -1.6 + px;
        // outline: 2px solid cyan;
        width: $red-radius * 2 + px;
        height: $red-radius + px;
        border-top-left-radius: $red-radius + px;
        border-top-right-radius: $red-radius + px;
        border: 4px solid #F80B42;
        border-bottom: 0;
        transform: rotate(180deg);
        transform-origin: 50% 100%;

        @media all and (min-width: 576px) {
            top: $red-radius / .6 * -1 + px;
            left: $red-radius / 7 * -1 + px;
            border-width: 7px;
        }

    }

    $arrow-with: 8;
    .red-arrow {
        position: relative;
        height: 100vh;
        top: $arrow-with * -1 + px;
        left: 0;

        @media all and (max-width: 576px) {
            margin-top: -70px;
        }

        // just drop the f** s** on mobile landscape
        @media screen and (max-height: 415px) and (orientation:landscape) {
            display: none;
        }

        > .line {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 2000px;
            width: $arrow-with + px;
            // top: 100%;
            // left: 0;
            // width:1000px;
            // height: 3px;
            background-color: #F80B42;
            transform: rotate(9deg);
            transform-origin: 100% 100%;


            @media all and (min-width: 576px) {
                transform: rotate(11deg);
                height: 150vh;
            }

            &::before {
                content: '';
                border-color:#F80B42;
                position: absolute;
                bottom: $arrow-with * -1 + px;
                left: $arrow-with / 2 + px;
                // margin-left: 3px;
                width:$arrow-with * 6 + px;
                height:$arrow-with * 6 + px;
                border-width:0 0 $arrow-with + px $arrow-with + px;
                transform: rotate(-45deg);
                transform-origin: 0 100%;
            }
        }
    }
}