/* tailwind config not available here. Pure SCSS only */

/**
 * Generic classes
 **/ 

 .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.circle {
    border-radius: 50%;
}

.circle-wrapper {
    @media all and (max-width: 576px) {
        transform-origin: 50% 50%;
        position: relative;
        height:150px;
        transform: scale(.7);
    }
}

.arrow2,
.arrow {
    i {
        display: block;
        position: absolute;
        left: 0;
    }
    &__head {
        border-style:solid;
        background:transparent;
    }
    
    &__body {
        left:5px;
        width:0;
        margin-top:0;
    }
}

.icon {
    display: inline-block;
    width: 196px;
    height: 196px;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
}

@import "_icons";
@import "_illustration";
