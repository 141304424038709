
$outerCircleWidth: 170px;
$innerCircleWidth: 60px;


#icon1 {
    .circle__outer {

        left: 50%;
        width: $outerCircleWidth;
        height: $outerCircleWidth;

        border-style: solid;
        border-color: #E87033;
        border-width: 9px;
        background:transparent;

        transform-origin: center;
        // transform: rotate(90deg);
        // outline: 1px solid red;

        @media all and (min-width: 576px) {
            left: 35%;
        } 
    }
    
    .circle__inner {
        width: 60px;
        height: 60px;
        background-color:#E87033;
    }
    .arrow {
        display: block;
        position: relative;
        top: 50%;
        left: 50%;
        transform: rotate(180deg);
        transform-origin: left;

        &__head {
            margin-top: -0.5em;
            top: 50%;
            // left:1px;
            width:1.3em;
            height:1.3em;
            border-width:0 0 5px 5px;
            border-color: #E87033;
            transform: translateX(-120px) rotate(45deg);
        }

        &__body {
            width: 100px;
            height:5px;
            top: 50%;
            background: #E87033;
            transform: translateX(-120px);
        }
    }
}
  
#icon2 {
    .circle {
        // left: 37%;
        border-style: solid;
        border-color: #50BDF1;
        border-width: 9px;
        background:transparent;  
        width: $outerCircleWidth;
        height: $outerCircleWidth;
    }
    
    .rect {
        position: absolute;
        background-color:#50BDF1;
        width: 70px;
        height: 70px;
        top: -10%;
        right: -10%;
    }

    .arrow {
        // outline: 1px solid red;
        display: inline-block;
        position: relative;
        top: 50%;
        left: -50%;
        width: 70px;
        height: 70px;
        &__head {
            border-color:#50BDF1;
            transform: rotate(-360deg);
            position: absolute;
            bottom: 0;
            left: 0%;
            width:2.3em;
            height:2.3em;
            border-width:0 0 6px 6px;
        }
        &__body {
            margin-left: -6px;
            top: 50%;
            left: 0%;
            width: 80px;
            height: 6px;
            background: #50BDF1;
            transform: rotate(-45deg);
        }
    }
}

#icon3 {

    // @media all and (min-width: 1025px) {
    //     .circle-wrapper {
    //         transform: scale(1.1);
    //     }
    // }

    .circle {
        left: 0;
        border-style: solid;
        border-color: #E87033;
        border-width: 9px;
        background:transparent;
        width: $outerCircleWidth;
        height: $outerCircleWidth;
        overflow: hidden;
    }
    
    .arrow {
        // outline: 1px solid red;
        position: absolute;
        top: 0;
        width: 85px;
        height: 85px;
        transform-origin: 100% 100%;
        transform: rotate(135deg);

        &__head {
            position: absolute;
            border-color: #E87033;
            top: 100%;
            left: auto;
            right: 55%;
            margin-top: -2px;
            transform: rotate(-135deg) translateY(60%);
            width:2em;
            height:2em;
            border-width:0 0 5px 5px;
        }
        &__body {
            top: 100%;
            margin-top: -2px;
            left: -25%;
            width: 75px;
            height: 5px;
            background-color: #E87033;
        }

        &-fat {
            margin-top: -22px;
            margin-left: -22px;
        }
    }
}

  